export enum EmissionStandard {
  Euro1 = 'euro1',
  Euro2 = 'euro2',
  Euro3 = 'euro3',
  Euro4 = 'euro4',
  Euro5 = 'euro5',
  Euro6 = 'euro6'
}

export default function useEmissionStandard() {
  const emissionStandards: {
    text: string;
    value: EmissionStandard;
    label?: string;
    color?: string; // hex
  }[] = [
    { text: 'Euro 1', value: EmissionStandard.Euro1, label: '1', color: '#016280' },
    { text: 'Euro 2', value: EmissionStandard.Euro2, label: '2', color: '#468ea7' },
    { text: 'Euro 3', value: EmissionStandard.Euro3, label: '3', color: '#89c0cf' },
    { text: 'Euro 4', value: EmissionStandard.Euro4, label: '4', color: '#b1d395' },
    { text: 'Euro 5', value: EmissionStandard.Euro5, label: '5', color: '#67bd4a' },
    { text: 'Euro 6', value: EmissionStandard.Euro6, label: '6', color: '#238b48' },
  ]

  return { emissionStandards }
}
