export default function useService() {
  const formatService = {
    type: (v: string) => {
      switch (v) {
        case 'service': return 'Serwis'
        case 'oil_change': return 'Wymiana oleju'
        case 'tire_change': return 'Wymiana opon'
        case 'technical_inspection': return 'Badanie techniczne'
        case 'repair': return 'Naprawa'
        case 'body_and_paint': return 'Usługi blacharsko-lakiernicze'
        case 'other': return 'Inne'
      }
    },

    icon: (v: string) => {
      switch (v) {
        case 'service': return 'mdi-toolbox-outline'
        case 'oil_change': return 'mdi-oil'
        case 'tire_change': return 'mdi-car-tire-alert'
        case 'technical_inspection': return 'mdi-clipboard-check-multiple-outline'
        case 'repair': return 'mdi-tools'
        case 'body_and_paint': return 'mdi-spray'
        case 'other': return 'mdi-asterisk'
      }
    },

    tireType: (v: string) => {
      switch (v) {
        case 'summer': return 'Opony letnie'
        case 'winter': return 'Opony zimowe'
        case 'multigrade': return 'Opony wielosezonowe'
        default: return 'Opony'
      }
    },

    tireTypeIcon: (v: string) => {
      switch (v) {
        case 'summer': return 'mdi-weather-partly-cloudy'
        case 'winter': return 'mdi-weather-snowy-heavy'
        case 'multigrade': return 'mdi-weather-partly-snowy'
        default: return 'mdi-car-tire-alert'
      }
    },

    mileage: (v: number) => `${v.toLocaleString()} km`
  }

  const serviceData = {
    serviceTypes: [
      { value: 'service', text: 'Serwis' },
      { value: 'oil_change', text: 'Wymiana oleju' },
      { value: 'tire_change', text: 'Wymiana opon' },
      { value: 'technical_inspection', text: 'Badanie techniczne' },
      { value: 'repair', text: 'Naprawa' },
      { value: 'body_and_paint', text: 'Usługi blacharsko-lakiernicze' },
      { value: 'other', text: 'Inne' }
    ],
    tireTypes: [
      { value: 'summer', text: 'Opony letnie', icon: 'mdi-weather-partly-cloudy' },
      { value: 'winter', text: 'Opony zimowe', icon: 'mdi-weather-snowy-heavy' },
      { value: 'multigrade', text: 'Opony wielosezonowe', icon: 'mdi-weather-partly-snowy' }
    ]
  }

  return { formatService, serviceData }
}
