


























































import useEmissionStandard from "@/use/emissionStandard";
import useService from "@/use/service";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "o-report-filter",

  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
  },

  props: {
    value: {
      type: Array,
      rquired: false,
      default: () => [],
    },
    type: {
      type: String,
      required: false,
      default: "service",
    },
  },

  setup(props, { emit }) {
    const model = ref(props.value);

    watch(model, (v) => emit("input", v), { deep: true });

    const rules: { [key: string]: (v: string) => string | boolean } = {
      required: (v) => !!v || "To pole jest wymagane.",
    };

    const { serviceData } = useService();
    const { emissionStandards } = useEmissionStandard();

    const columns = computed(() => {
      const items = [];

      items.push({
        value: "vehicleRegistration",
        text: "Numer rejestracyjny pojazdu",
        type: "string",
      });
      items.push({
        value: "vehicleVinNumber",
        text: "Numer VIN pojazdu",
        type: "string",
      });
      items.push({
        value: "vehicleModel",
        text: "Model pojazdu",
        type: "string",
      });
      items.push({
        value: "vehicleEngineCapacity",
        text: "Pojemność silnika pojazdu",
        type: "number",
      });
      items.push({
        value: "vehicleEmissionStandard",
        text: "Norma emisji spalin pojazdu",
        type: "enum",
        enum: emissionStandards,
      });
      items.push({
        value: "vehicleMileage",
        text: "Przebieg pojazdu",
        type: "number",
      });
      items.push({
        value: "vehicleDriver",
        text: "Kierowca pojazdu",
        type: "string",
      });
      items.push({
        value: "vehicleComment",
        text: "Komentarz pojazdu",
        type: "string",
      });

      if (props.type === "service") {
        // Serwis
        items.push({
          value: "serviceType",
          text: "Typ serwisu",
          type: "enum",
          enum: serviceData.serviceTypes,
        });
        items.push({
          value: "serviceCost",
          text: "Koszt serwisu",
          type: "number",
        });
        items.push({
          value: "serviceDescription",
          text: "Opis serwisu",
          type: "string",
        });
        items.push({
          value: "serviceTireStoragePlace",
          text: "Miejsce przechowywania opon dla danego serwisu",
          type: "string",
        });
        items.push({
          value: "serviceDate",
          text: "Data wykonania serwisu",
          type: "date",
        });
      } else if (props.type === "leasing") {
        // Leasing
        items.push({
          value: "leasingInstallment",
          text: "Rata leasingu",
          type: "number",
        });
        items.push({
          value: "leasingEndDate",
          text: "Data zakończenia leasingu",
          type: "date",
        });
        items.push({
          value: "leasingMileage",
          text: "Przebieg według umowy leasingu",
          type: "number",
        });
      } else if (props.type === "insurance") {
        // Ubezpieczenie
        items.push({
          value: "insuranceEndDate",
          text: "Data końca polisy",
          type: "date",
        });
        items.push({
          value: "insurancePremium",
          text: "Składka ubezpieczenia",
          type: "number",
        });
      } else if (props.type === "fuelCard") {
        // Transakcje z kart paliwowych
        items.push({
          value: "productName",
          text: "Nazwa produktu",
          type: "string",
        });
        items.push({
          value: "productType",
          text: "Typ produktu",
          type: "enum",
          enum: [
            { value: "petrol", text: "Paliwo" },
            { value: "carwash", text: "Myjnia" },
            { value: "tolls", text: "Opłaty drogowe" },
            { value: "other", text: "Inne" },
          ],
        });
        items.push({ value: "date", text: "Data transakcji", type: "date" });
        items.push({
          value: "invoiceNumber",
          text: "Numer faktury",
          type: "string",
        });
        items.push({
          value: "type",
          text: "Rodzaj karty paliwowej",
          type: "enum",
          enum: [
            { value: "orlen", text: "Orlen" },
            { value: "bp", text: "BP" },
          ],
        });
        items.push({ value: "price", text: "Cena (brutto)", type: "number" });
        items.push({ value: "amount", text: "Ilość", type: "number" });
        items.push({ value: "total", text: "Wartość (netto)", type: "number" });
      }

      return items;
    });

    const operators = (type = "string") => {
      const items = [];

      items.push({ value: "=", text: "Wartość jest równa" });

      if (type === "string") {
        items.push({ value: "LIKE", text: "Wartość zawiera" });
      } else if (["number", "date"].includes(type)) {
        items.push({ value: "<=", text: "Wartość jest mniejsza bądź równa" });
        items.push({ value: ">=", text: "Wartość jest większa bądź równa" });
        items.push({ value: "<", text: "Wartość jest mniejsza" });
        items.push({ value: ">", text: "Wartość jest większa" });
      }

      return items;
    };

    const logicalOperators = [
      { value: "AND", text: "ORAZ" },
      { value: "OR", text: "LUB" },
    ];

    const addRuleGroup = (item: any[]) => {
      if (item.length >= 1) item.push("AND");
      item.push([]);
    };

    const addRule = (item: any[]) => {
      if (item.length >= 1) item.push("AND");
      item.push({ column: null, operator: null, value: null });
    };

    const deleteItem = (index: number) => {
      model.value.splice(index, 1);

      if (
        typeof model.value[0] === "string" ||
        model.value[0] instanceof String
      )
        deleteItem(0);
      if (
        typeof model.value[model.value.length - 1] === "string" ||
        model.value[model.value.length - 1] instanceof String
      )
        deleteItem(model.value.length - 1);

      let lastItemType: string | null = null;

      model.value.forEach((item, i) => {
        if (lastItemType === typeof item) deleteItem(i);
        lastItemType = typeof item;
      });
    };

    const getColumn = (name: string) => {
      const column = columns.value.find((column) => column.value == name);

      if (column) return column;
      else return null;
    };

    return {
      model,
      rules,
      columns,
      operators,
      logicalOperators,
      addRuleGroup,
      addRule,
      deleteItem,
      getColumn,
    };
  },
});
